import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {SEARCH_TYPE} from '../../../quick-search/quick-search.enums';
import {TabItem} from '../../models/TabItem';
import {TabService} from '../../service/tab.service';
import {SettingsService} from '../../../../service/settings.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    logoURL: string;
    tabSubject$: Subject<TabItem[]>;
    SEARCH_TYPE = SEARCH_TYPE;

    constructor(private tabService: TabService, private settingsService: SettingsService) {
    }

    ngOnInit() {
        this.tabSubject$ = this.tabService.getTabSubject();
        this.tabService.loadTabs();
        this.logoURL = this.settingsService.settings.theme.logoURL;
    }

    onCloseAllTabs() {
        this.tabService.clearTabs();
    }
}
