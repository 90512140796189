import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {User, UserManager} from 'oidc-client';
import {from, Observable, of} from 'rxjs';
import {AUAClientService, UserDTO} from '../../api/aua_angular-client';
import {flatMap, map} from 'rxjs/operators';
import {AuaApiService} from '../../../service/aua-api.service';
import {SettingsService} from '../../../service/settings.service';
import {LoginService} from '../../../service/login.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private manager: UserManager;
    private authUser: User = null;
    private auaUser: UserDTO;

    constructor(private router: Router,
                private auaClientService: AUAClientService,
                private loginService: LoginService,
                private settingsService: SettingsService,
                private auaApiService: AuaApiService) {
    }

    /**
     * Kicks off authentication process of oicd-client
     */
    startAuthentication(): Promise<void> {

        // Make sure no tabs appear on new login
        sessionStorage.removeItem('tabs');

        const userManagerSettings = this.settingsService.settings.oicdClientSettings;
        this.manager = new UserManager(userManagerSettings);

        return this.manager.signinRedirect();
    }

    /**
     * Perform action when authentication is confirmed
     */
    completeAuthentication(): void {

        if (!this.manager) {
            const userManagerSettings = this.settingsService.settings.oicdClientSettings;
            this.manager = new UserManager(userManagerSettings);

            // Register for expired token:
            this.manager.events.addAccessTokenExpired(() => {
                this.startSignOut('AUTH_TOKEN_EXPIRED_LOGOUT');
            });

            this.manager.events.addSilentRenewError(() => {
                this.startSignOut('AUTH_TOKEN_EXPIRED_LOGOUT');
            });
        }

        from(this.manager.signinRedirectCallback())
            .pipe(map((authUser) => {

                // Exit if user is not permitted to access helpdesk
                if (!this.canUse(authUser.profile.roles)) {
                    throw new Error('AUTH_NO_ROLE_PERMISSION_LOGOUT');
                }

                return authUser;

            })).pipe(flatMap(authUser => {

            this.authUser = authUser;
            this.updateAPIAuthorization(authUser);

            return this.auaApiService.getUser(this.authUser.profile.sub);

        })).subscribe(response => {

            if (response.user_get_detail_response.length) {
                this.auaUser = response.user_get_detail_response[0].user;
            }

            this.router.navigate([this.settingsService.settings.postLoginRoute]);

        }, err => {
            return this.startSignOut(err.message);
        });

    }

    /**
     * Get user stored by oicd-client
     */
    getUser$(): Observable<User> {
        if (!this.manager) {
            const userManagerSettings = this.settingsService.settings.oicdClientSettings;
            this.manager = new UserManager(userManagerSettings);
        }

        return from(this.manager.getUser());
    }

    /**
     * Returns aua-information of logged in user
     */
    getAuAUser$(): Observable<UserDTO> {
        if (this.auaUser) {
            return of(this.auaUser);
        } else {
            return this.getUser$()
                .pipe(flatMap(authUser => {
                    return this.auaApiService.getUser(authUser.profile.sub);
                })).pipe(map(
                    (response) => {
                        return response.user_get_detail_response[0].user;
                    }
                ));
        }
    }

    /**
     * Updates authorization with received token
     * @param authUser with token
     */
    updateAPIAuthorization(authUser: User) {
        this.auaClientService.configuration.apiKeys['Authorization'] = authUser.id_token;
    }

    /**
     * Defines Roles that are allowed to login
     * @param roles to check against
     */
    canUse(roles: Array<string>) {
        return roles.includes('IA_ADMIN') || roles.includes('IA_SUPPORTER');
    }

    /**
     * Starts signout
     * @param logoutReason general reason
     */
    startSignOut(logoutReason: string = ''): void {
        const oicdstorageKey = this.settingsService.settings.oicdStorageKey;

        // Remove stored data
        if (typeof oicdstorageKey !== 'undefined') {
            sessionStorage.removeItem('tabs');
            sessionStorage.removeItem(oicdstorageKey);
        }

        if (logoutReason) {
            this.loginService.setLogoutReason(logoutReason);
        }

        this.router.navigate(['/login']);
    }
}
