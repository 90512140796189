/**
 * ioneaccess Authentication and User Aggregator (AUA) API
 * API for ioneacces Authenticator and User Aggregator (AUA). This document does not cover the OpenID Connect part!
 *
 * OpenAPI spec version: 1.1.2
 * Contact: christian.schlatter@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface GroupDTO { 
    name: string;
    group_type: string;
    membership_type: GroupDTO.MembershipTypeEnum;
}
export namespace GroupDTO {
    export type MembershipTypeEnum = 'MaySupport' | 'HasLicenses';
    export const MembershipTypeEnum = {
        MaySupport: 'MaySupport' as MembershipTypeEnum,
        HasLicenses: 'HasLicenses' as MembershipTypeEnum
    };
}
