import {Injectable} from '@angular/core';
import {
    PROSSystem1Service
} from '../modules/api/pros_angular-client';


@Injectable({
    providedIn: 'root'
})
export class ProsApiService {

    constructor(private prosSystemService: PROSSystem1Service) {
    }


}
