<app-header fxHide.xs></app-header>
<app-mobile-header fxHide.gt-xs></app-mobile-header>

<app-popup-dialog-manager></app-popup-dialog-manager>

<div class="hd--notifications">
    <app-notification></app-notification>
</div>

<div class="hd--standard-page" [ngClass.xs]="'xs'">
    <div class="hd--content" fxLayout="row" fxLayoutAlign="center">
        <div fxFlex="90%">
            <router-outlet></router-outlet>
            <span class="version-number">0.2.23</span>
        </div>
    </div>
</div>
