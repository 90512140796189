/**
 * ioneaccess Identity Provider (IDP) API
 * API for IDP (ioneacces Identity Provider). This document does not cover the OpenID Connect part!
 *
 * OpenAPI spec version: 1.1.0
 * Contact: christian.schlatter@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GroupSetRequest } from '../model/groupSetRequest';
import { SetEmailResponse } from '../model/setEmailResponse';
import { UserCreateDTO } from '../model/userCreateDTO';
import { UserCreateResponse } from '../model/userCreateResponse';
import { UserDTO } from '../model/userDTO';
import { UserDeleteRequest } from '../model/userDeleteRequest';
import { UserDeleteResponse } from '../model/userDeleteResponse';
import { UserGetRequest } from '../model/userGetRequest';
import { UserGetResponse } from '../model/userGetResponse';
import { UserModifyResponse } from '../model/userModifyResponse';
import { UserSearchRequest } from '../model/userSearchRequest';
import { UserSearchResponse } from '../model/userSearchResponse';
import { UserSetEmailRequest } from '../model/userSetEmailRequest';
import { UserSetGroupResponse } from '../model/userSetGroupResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {User} from 'oidc-client';
import {SettingsHttpService} from '../../../../service/settings.http.service';


@Injectable()
export class OperatorService {

    protected basePath = 'https://igate.ionedev.com/idp/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient,  protected httpService: SettingsHttpService, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
        if (httpService) {
            httpService.getSettingsSubject().subscribe((settings) => {
                this.basePath = settings.basePathIDP;
                if (settings.oicdStorageKey) {
                    const user: User = JSON.parse(sessionStorage.getItem(settings.oicdStorageKey));
                    this.configuration.apiKeys = {
                        Authorization: user ? user.id_token : null
                    };
                }
            });
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * create new user
     * Create new user
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param user Parameters for user creation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserCreateDTO>, observe?: 'body', reportProgress?: boolean): Observable<UserCreateResponse>;
    public createUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserCreateDTO>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserCreateResponse>>;
    public createUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserCreateDTO>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserCreateResponse>>;
    public createUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserCreateDTO>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createUser.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserCreateResponse>(`${this.basePath}/user/create`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a specific user
     * Delete a specific user by email or by subject. Either email or subject has to be specified
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param user Parameters for user deletion
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserDeleteRequest>, observe?: 'body', reportProgress?: boolean): Observable<UserDeleteResponse>;
    public deleteUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserDeleteRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDeleteResponse>>;
    public deleteUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserDeleteRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDeleteResponse>>;
    public deleteUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserDeleteRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling deleteUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling deleteUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling deleteUser.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserDeleteResponse>(`${this.basePath}/user/delete`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return a specific user
     * Return a specific user by email or by subject. Either email or subject has to be specified
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param user Parameters for fetching user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserGetRequest>, observe?: 'body', reportProgress?: boolean): Observable<UserGetResponse>;
    public getUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserGetRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserGetResponse>>;
    public getUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserGetRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserGetResponse>>;
    public getUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserGetRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getUser.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserGetResponse>(`${this.basePath}/user/get`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify an existing user
     * Modify an existing user
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param user User to create nventory item to add
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserDTO>, observe?: 'body', reportProgress?: boolean): Observable<UserModifyResponse>;
    public modifyUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserDTO>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserModifyResponse>>;
    public modifyUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserDTO>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserModifyResponse>>;
    public modifyUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user?: Array<UserDTO>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling modifyUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling modifyUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling modifyUser.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserModifyResponse>(`${this.basePath}/user/modify`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search users by email
     * Search users by email
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param user Part of email or subject to search for. Limited to 50 results
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchUserByEmail(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserSearchRequest, observe?: 'body', reportProgress?: boolean): Observable<UserSearchResponse>;
    public searchUserByEmail(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserSearchRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSearchResponse>>;
    public searchUserByEmail(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserSearchRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSearchResponse>>;
    public searchUserByEmail(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserSearchRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling searchUserByEmail.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling searchUserByEmail.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling searchUserByEmail.');
        }

        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling searchUserByEmail.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserSearchResponse>(`${this.basePath}/user/search`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set a new login email address
     * Set a new login email address
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param user Users email to change
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setEmail(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: Array<UserSetEmailRequest>, observe?: 'body', reportProgress?: boolean): Observable<SetEmailResponse>;
    public setEmail(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: Array<UserSetEmailRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SetEmailResponse>>;
    public setEmail(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: Array<UserSetEmailRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SetEmailResponse>>;
    public setEmail(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: Array<UserSetEmailRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling setEmail.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling setEmail.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling setEmail.');
        }

        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling setEmail.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SetEmailResponse>(`${this.basePath}/user/setEmail`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set groups of a certain user
     * set groups of a certain user
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param group Array with new user groups
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userSetGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, group: Array<GroupSetRequest>, observe?: 'body', reportProgress?: boolean): Observable<UserSetGroupResponse>;
    public userSetGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, group: Array<GroupSetRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSetGroupResponse>>;
    public userSetGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, group: Array<GroupSetRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSetGroupResponse>>;
    public userSetGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, group: Array<GroupSetRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling userSetGroups.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling userSetGroups.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling userSetGroups.');
        }

        if (group === null || group === undefined) {
            throw new Error('Required parameter group was null or undefined when calling userSetGroups.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserSetGroupResponse>(`${this.basePath}/user/setGroup`,
            group,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
