import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    /**
     * Checks if user is allowed to access otherwise inits. authentication process
     * @return user is allowed to activate route
     */
    AuthGuardService.prototype.canActivate = function () {
        var _this = this;
        return this.authService.getUser$().pipe(map(function (user) {
            if (user) {
                return true;
            }
            else {
                _this.router.navigate(['/login']);
                return false;
            }
        }));
    };
    AuthGuardService.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
