/**
 * ioneaccess Authentication and User Aggregator (AUA) API
 * API for ioneacces Authenticator and User Aggregator (AUA). This document does not cover the OpenID Connect part!
 *
 * OpenAPI spec version: 1.1.2
 * Contact: christian.schlatter@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UserQuickSearchRequest { 
    search_text: string;
    /**
     * Defines which field should be queried by given search_text.
     */
    search_type: UserQuickSearchRequest.SearchTypeEnum;
    /**
     * When true, given search_text and search_type will be also used to search in user identities.
     */
    search_in_identities: boolean;
}
export namespace UserQuickSearchRequest {
    export type SearchTypeEnum = 'email' | 'subject' | 'email_or_subject';
    export const SearchTypeEnum = {
        Email: 'email' as SearchTypeEnum,
        Subject: 'subject' as SearchTypeEnum,
        EmailOrSubject: 'email_or_subject' as SearchTypeEnum
    };
}
