import { ClientService, OperatorService } from '../modules/api/idp_angular-client';
import { map } from 'rxjs/operators';
import { MessengerService } from '../modules/shared/service/messenger.service';
import * as i0 from "@angular/core";
import * as i1 from "../modules/api/idp_angular-client/api/client.service";
import * as i2 from "../modules/api/idp_angular-client/api/operator.service";
import * as i3 from "../modules/shared/service/messenger.service";
var IdpApiService = /** @class */ (function () {
    function IdpApiService(idPClientService, idPOperatorService, messengerService) {
        this.idPClientService = idPClientService;
        this.idPOperatorService = idPOperatorService;
        this.messengerService = messengerService;
    }
    /**
     * Triggers reset email.
     * @param email to reset
     */
    IdpApiService.prototype.resetPassword = function (email) {
        return this.idPClientService.resetPasswordRequest(email);
    };
    /**
     * Deletes user in IDP.
     * @param subject to delete
     */
    IdpApiService.prototype.deleteUser = function (subject) {
        var userDeleteRequest = [{
                subject: subject
            }];
        return this.idPOperatorService.deleteUser('', '', '', userDeleteRequest);
    };
    /**
     * Gets the user from IDP
     * @param subject
     */
    IdpApiService.prototype.getUser = function (subject) {
        var getUserRequest = [{
                subject: subject
            }];
        return this.idPOperatorService.getUser('', '', '', getUserRequest);
    };
    /**
     * Set new user email
     * @param subject to change
     * @param email new email
     */
    IdpApiService.prototype.setEmail = function (subject, email) {
        var _this = this;
        var setEmailRequest = [{
                subject: subject,
                email: email
            }];
        return this.idPClientService.setEmail('', '', '', setEmailRequest).pipe(map(function (setEmailResponse) {
            if (setEmailResponse.global_response.result_state === 'WARN') {
                var detailResponses = setEmailResponse.user_change_email_detail_responses;
                for (var _i = 0, detailResponses_1 = detailResponses; _i < detailResponses_1.length; _i++) {
                    var detailResponse = detailResponses_1[_i];
                    _this.messengerService.sendNotification(detailResponse.detail_result_response.result_code, 'IDP | setEmail');
                }
            }
            return setEmailResponse;
        }));
    };
    IdpApiService.ngInjectableDef = i0.defineInjectable({ factory: function IdpApiService_Factory() { return new IdpApiService(i0.inject(i1.ClientService), i0.inject(i2.OperatorService), i0.inject(i3.MessengerService)); }, token: IdpApiService, providedIn: "root" });
    return IdpApiService;
}());
export { IdpApiService };
