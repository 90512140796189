import { Router } from '@angular/router';
import { UserManager } from 'oidc-client';
import { from, of } from 'rxjs';
import { AUAClientService } from '../../api/aua_angular-client';
import { flatMap, map } from 'rxjs/operators';
import { AuaApiService } from '../../../service/aua-api.service';
import { SettingsService } from '../../../service/settings.service';
import { LoginService } from '../../../service/login.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../api/aua_angular-client/api/aUAClient.service";
import * as i3 from "../../../service/login.service";
import * as i4 from "../../../service/settings.service";
import * as i5 from "../../../service/aua-api.service";
var AuthService = /** @class */ (function () {
    function AuthService(router, auaClientService, loginService, settingsService, auaApiService) {
        this.router = router;
        this.auaClientService = auaClientService;
        this.loginService = loginService;
        this.settingsService = settingsService;
        this.auaApiService = auaApiService;
        this.authUser = null;
    }
    /**
     * Kicks off authentication process of oicd-client
     */
    AuthService.prototype.startAuthentication = function () {
        // Make sure no tabs appear on new login
        sessionStorage.removeItem('tabs');
        var userManagerSettings = this.settingsService.settings.oicdClientSettings;
        this.manager = new UserManager(userManagerSettings);
        return this.manager.signinRedirect();
    };
    /**
     * Perform action when authentication is confirmed
     */
    AuthService.prototype.completeAuthentication = function () {
        var _this = this;
        if (!this.manager) {
            var userManagerSettings = this.settingsService.settings.oicdClientSettings;
            this.manager = new UserManager(userManagerSettings);
            // Register for expired token:
            this.manager.events.addAccessTokenExpired(function () {
                _this.startSignOut('AUTH_TOKEN_EXPIRED_LOGOUT');
            });
            this.manager.events.addSilentRenewError(function () {
                _this.startSignOut('AUTH_TOKEN_EXPIRED_LOGOUT');
            });
        }
        from(this.manager.signinRedirectCallback())
            .pipe(map(function (authUser) {
            // Exit if user is not permitted to access helpdesk
            if (!_this.canUse(authUser.profile.roles)) {
                throw new Error('AUTH_NO_ROLE_PERMISSION_LOGOUT');
            }
            return authUser;
        })).pipe(flatMap(function (authUser) {
            _this.authUser = authUser;
            _this.updateAPIAuthorization(authUser);
            return _this.auaApiService.getUser(_this.authUser.profile.sub);
        })).subscribe(function (response) {
            if (response.user_get_detail_response.length) {
                _this.auaUser = response.user_get_detail_response[0].user;
            }
            _this.router.navigate([_this.settingsService.settings.postLoginRoute]);
        }, function (err) {
            return _this.startSignOut(err.message);
        });
    };
    /**
     * Get user stored by oicd-client
     */
    AuthService.prototype.getUser$ = function () {
        if (!this.manager) {
            var userManagerSettings = this.settingsService.settings.oicdClientSettings;
            this.manager = new UserManager(userManagerSettings);
        }
        return from(this.manager.getUser());
    };
    /**
     * Returns aua-information of logged in user
     */
    AuthService.prototype.getAuAUser$ = function () {
        var _this = this;
        if (this.auaUser) {
            return of(this.auaUser);
        }
        else {
            return this.getUser$()
                .pipe(flatMap(function (authUser) {
                return _this.auaApiService.getUser(authUser.profile.sub);
            })).pipe(map(function (response) {
                return response.user_get_detail_response[0].user;
            }));
        }
    };
    /**
     * Updates authorization with received token
     * @param authUser with token
     */
    AuthService.prototype.updateAPIAuthorization = function (authUser) {
        this.auaClientService.configuration.apiKeys['Authorization'] = authUser.id_token;
    };
    /**
     * Defines Roles that are allowed to login
     * @param roles to check against
     */
    AuthService.prototype.canUse = function (roles) {
        return roles.includes('IA_ADMIN') || roles.includes('IA_SUPPORTER');
    };
    /**
     * Starts signout
     * @param logoutReason general reason
     */
    AuthService.prototype.startSignOut = function (logoutReason) {
        if (logoutReason === void 0) { logoutReason = ''; }
        var oicdstorageKey = this.settingsService.settings.oicdStorageKey;
        // Remove stored data
        if (typeof oicdstorageKey !== 'undefined') {
            sessionStorage.removeItem('tabs');
            sessionStorage.removeItem(oicdstorageKey);
        }
        if (logoutReason) {
            this.loginService.setLogoutReason(logoutReason);
        }
        this.router.navigate(['/login']);
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.Router), i0.inject(i2.AUAClientService), i0.inject(i3.LoginService), i0.inject(i4.SettingsService), i0.inject(i5.AuaApiService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
