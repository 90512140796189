import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./settings.service";
var SettingsHttpService = /** @class */ (function () {
    function SettingsHttpService(http, settingsService) {
        this.http = http;
        this.settingsService = settingsService;
        this.promiseResolved = new BehaviorSubject(this.settingsService.settings);
    }
    /**
     * Requests the settings file and sends out the response.
     */
    SettingsHttpService.prototype.initializeApp = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.http.get('assets/settings/settings.json')
                .toPromise()
                .then(function (response) {
                // Stores the loaded settings in the service
                _this.settingsService.settings = response;
                _this.promiseResolved.next(_this.settingsService.settings);
                resolve();
            });
        });
    };
    /**
     * Subject to get new settings
     */
    SettingsHttpService.prototype.getSettingsSubject = function () {
        return this.promiseResolved;
    };
    SettingsHttpService.ngInjectableDef = i0.defineInjectable({ factory: function SettingsHttpService_Factory() { return new SettingsHttpService(i0.inject(i1.HttpClient), i0.inject(i2.SettingsService)); }, token: SettingsHttpService, providedIn: "root" });
    return SettingsHttpService;
}());
export { SettingsHttpService };
