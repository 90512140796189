/**
 * ioneaccess Product and Order-System (PROS) API
 * API for ioneaccess Product and Order-System (PROS) API aka ia_sales_system REST services
 *
 * OpenAPI spec version: 1.0.15
 * Contact: florian.mischler@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { OrderActivateCouponRequest1 } from '../model/orderActivateCouponRequest1';
import { OrderActivateCouponResponse1 } from '../model/orderActivateCouponResponse1';
import { OrderCouponStateAssignedToUnassignedRequest1 } from '../model/orderCouponStateAssignedToUnassignedRequest1';
import { OrderCouponStateAssignedToUnassignedResponse1 } from '../model/orderCouponStateAssignedToUnassignedResponse1';
import { OrderGenerateForProductRequest1 } from '../model/orderGenerateForProductRequest1';
import { OrderGenerateForProductResponse1 } from '../model/orderGenerateForProductResponse1';
import { OrderGetBySubjectRequest1 } from '../model/orderGetBySubjectRequest1';
import { OrderGetBySubjectResponse1 } from '../model/orderGetBySubjectResponse1';
import { OrderQuickSearchCouponCodeRequest1 } from '../model/orderQuickSearchCouponCodeRequest1';
import { OrderQuickSearchCouponCodeResponse1 } from '../model/orderQuickSearchCouponCodeResponse1';
import { ProductLookUpEproductByProductReferenceRequest1 } from '../model/productLookUpEproductByProductReferenceRequest1';
import { ProductLookUpEproductByProductReferenceResponse1 } from '../model/productLookUpEproductByProductReferenceResponse1';
import { ProductLookUpMandantRequest1 } from '../model/productLookUpMandantRequest1';
import { ProductLookUpMandantResponse1 } from '../model/productLookUpMandantResponse1';
import { ProductLookUpSkuRequest1 } from '../model/productLookUpSkuRequest1';
import { ProductLookUpSkuResponse1 } from '../model/productLookUpSkuResponse1';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {User} from 'oidc-client';
import {SettingsHttpService} from '../../../../service/settings.http.service';


@Injectable()
export class PROSSystem1Service {

    protected basePath = 'https://pros.ionedev.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, protected httpService: SettingsHttpService, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
        if (httpService) {
            httpService.getSettingsSubject().subscribe((settings) => {
                this.basePath = settings.basePathPROS;
                if (settings.oicdStorageKey) {
                    const user: User = JSON.parse(sessionStorage.getItem(settings.oicdStorageKey));
                    this.configuration.apiKeys = {
                        Authorization: user ? user.id_token : null
                    };
                }
            });
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Activate coupons, return license order items.
     * Activate coupons, return license order items.
     * @param OrderActivateCouponRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateCoupon(OrderActivateCouponRequest1?: OrderActivateCouponRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderActivateCouponResponse1>;
    public activateCoupon(OrderActivateCouponRequest1?: OrderActivateCouponRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderActivateCouponResponse1>>;
    public activateCoupon(OrderActivateCouponRequest1?: OrderActivateCouponRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderActivateCouponResponse1>>;
    public activateCoupon(OrderActivateCouponRequest1?: OrderActivateCouponRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Cookie) required
        if (this.configuration.apiKeys["Cookie"]) {
            headers = headers.set('Cookie', this.configuration.apiKeys["Cookie"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderActivateCouponResponse1>(`${this.basePath}/rpc/orderSystem/v1/order/activateCoupon`,
            OrderActivateCouponRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Coupons state from COUPON_STATE_ASSIGNED to COUPON_STATE_UNASSIGNED
     * Change coupons code from COUPON_STATE_ASSIGNED to COUPON_STATE_UNASSIGNED, also all license order items of the coupon(s)
     * @param OrderCouponStateAssignedToUnassignedRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeCouponStateAssignedToUnassigned(OrderCouponStateAssignedToUnassignedRequest1?: OrderCouponStateAssignedToUnassignedRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderCouponStateAssignedToUnassignedResponse1>;
    public changeCouponStateAssignedToUnassigned(OrderCouponStateAssignedToUnassignedRequest1?: OrderCouponStateAssignedToUnassignedRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderCouponStateAssignedToUnassignedResponse1>>;
    public changeCouponStateAssignedToUnassigned(OrderCouponStateAssignedToUnassignedRequest1?: OrderCouponStateAssignedToUnassignedRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderCouponStateAssignedToUnassignedResponse1>>;
    public changeCouponStateAssignedToUnassigned(OrderCouponStateAssignedToUnassignedRequest1?: OrderCouponStateAssignedToUnassignedRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Cookie) required
        if (this.configuration.apiKeys["Cookie"]) {
            headers = headers.set('Cookie', this.configuration.apiKeys["Cookie"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderCouponStateAssignedToUnassignedResponse1>(`${this.basePath}/rpc/orderSystem/v1/changeCouponStateAssignedToUnassigned`,
            OrderCouponStateAssignedToUnassignedRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create/Generate and get license order items by subject and product reference, return license order items.
     * Create/Generate and get license order items by subject and product reference, return license order items. This ressource is used for free products, where a coupon code is not needed.
     * @param OrderGenerateForProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateForProduct(OrderGenerateForProductRequest1?: OrderGenerateForProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderGenerateForProductResponse1>;
    public generateForProduct(OrderGenerateForProductRequest1?: OrderGenerateForProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderGenerateForProductResponse1>>;
    public generateForProduct(OrderGenerateForProductRequest1?: OrderGenerateForProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderGenerateForProductResponse1>>;
    public generateForProduct(OrderGenerateForProductRequest1?: OrderGenerateForProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Cookie) required
        if (this.configuration.apiKeys["Cookie"]) {
            headers = headers.set('Cookie', this.configuration.apiKeys["Cookie"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderGenerateForProductResponse1>(`${this.basePath}/rpc/orderSystem/v1/order/generateForProduct`,
            OrderGenerateForProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get license order items by subject, return license order items.
     * Get license order items by subjects, return license order items.
     * @param OrderGetBySubjectRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBySubject(OrderGetBySubjectRequest1?: OrderGetBySubjectRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderGetBySubjectResponse1>;
    public getBySubject(OrderGetBySubjectRequest1?: OrderGetBySubjectRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderGetBySubjectResponse1>>;
    public getBySubject(OrderGetBySubjectRequest1?: OrderGetBySubjectRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderGetBySubjectResponse1>>;
    public getBySubject(OrderGetBySubjectRequest1?: OrderGetBySubjectRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Cookie) required
        if (this.configuration.apiKeys["Cookie"]) {
            headers = headers.set('Cookie', this.configuration.apiKeys["Cookie"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderGetBySubjectResponse1>(`${this.basePath}/rpc/orderSystem/v1/order/getBySubject`,
            OrderGetBySubjectRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all eproducts with given product references
     * Get all eproducts with given product references
     * @param ProductLookUpEproductByProductReferenceRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lookUpEproductByProductReference(ProductLookUpEproductByProductReferenceRequest1?: ProductLookUpEproductByProductReferenceRequest1, observe?: 'body', reportProgress?: boolean): Observable<ProductLookUpEproductByProductReferenceResponse1>;
    public lookUpEproductByProductReference(ProductLookUpEproductByProductReferenceRequest1?: ProductLookUpEproductByProductReferenceRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductLookUpEproductByProductReferenceResponse1>>;
    public lookUpEproductByProductReference(ProductLookUpEproductByProductReferenceRequest1?: ProductLookUpEproductByProductReferenceRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductLookUpEproductByProductReferenceResponse1>>;
    public lookUpEproductByProductReference(ProductLookUpEproductByProductReferenceRequest1?: ProductLookUpEproductByProductReferenceRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Cookie) required
        if (this.configuration.apiKeys["Cookie"]) {
            headers = headers.set('Cookie', this.configuration.apiKeys["Cookie"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductLookUpEproductByProductReferenceResponse1>(`${this.basePath}/rpc/productSystem/v1/product/lookUpEproductByProductReference`,
            ProductLookUpEproductByProductReferenceRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all productreferences of the mandant
     * Get all products of the mandant by mandant_identifier
     * @param ProductLookUpMandantRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lookUpMandant(ProductLookUpMandantRequest1?: ProductLookUpMandantRequest1, observe?: 'body', reportProgress?: boolean): Observable<ProductLookUpMandantResponse1>;
    public lookUpMandant(ProductLookUpMandantRequest1?: ProductLookUpMandantRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductLookUpMandantResponse1>>;
    public lookUpMandant(ProductLookUpMandantRequest1?: ProductLookUpMandantRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductLookUpMandantResponse1>>;
    public lookUpMandant(ProductLookUpMandantRequest1?: ProductLookUpMandantRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Cookie) required
        if (this.configuration.apiKeys["Cookie"]) {
            headers = headers.set('Cookie', this.configuration.apiKeys["Cookie"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductLookUpMandantResponse1>(`${this.basePath}/rpc/productSystem/v1/product/lookUpMandant`,
            ProductLookUpMandantRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get course reference by subjects and skus, return course references.
     * Get course reference by subjects and skus, return course references.
     * @param ProductLookUpSkuRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lookUpSku(ProductLookUpSkuRequest1?: ProductLookUpSkuRequest1, observe?: 'body', reportProgress?: boolean): Observable<ProductLookUpSkuResponse1>;
    public lookUpSku(ProductLookUpSkuRequest1?: ProductLookUpSkuRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductLookUpSkuResponse1>>;
    public lookUpSku(ProductLookUpSkuRequest1?: ProductLookUpSkuRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductLookUpSkuResponse1>>;
    public lookUpSku(ProductLookUpSkuRequest1?: ProductLookUpSkuRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Cookie) required
        if (this.configuration.apiKeys["Cookie"]) {
            headers = headers.set('Cookie', this.configuration.apiKeys["Cookie"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductLookUpSkuResponse1>(`${this.basePath}/rpc/productSystem/v1/product/lookUpSku`,
            ProductLookUpSkuRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all products of the coupon code
     * Get all products of the coupon code
     * @param OrderQuickSearchCouponCodeRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quickSearchCouponCode(OrderQuickSearchCouponCodeRequest1?: OrderQuickSearchCouponCodeRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderQuickSearchCouponCodeResponse1>;
    public quickSearchCouponCode(OrderQuickSearchCouponCodeRequest1?: OrderQuickSearchCouponCodeRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderQuickSearchCouponCodeResponse1>>;
    public quickSearchCouponCode(OrderQuickSearchCouponCodeRequest1?: OrderQuickSearchCouponCodeRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderQuickSearchCouponCodeResponse1>>;
    public quickSearchCouponCode(OrderQuickSearchCouponCodeRequest1?: OrderQuickSearchCouponCodeRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Cookie) required
        if (this.configuration.apiKeys["Cookie"]) {
            headers = headers.set('Cookie', this.configuration.apiKeys["Cookie"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderQuickSearchCouponCodeResponse1>(`${this.basePath}/rpc/orderSystem/v1/order/quickSearchCouponCode`,
            OrderQuickSearchCouponCodeRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
