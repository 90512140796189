/**
 *  WARNING: IMPORT THIS MODULE IN APP-MODULE ONLY! forRoot() - Modules should only be provided once!
 */

import {NgModule} from '@angular/core';

import {ApiModule as LimaAPIModule} from './lima_angular-client';
import {ApiModule as ProsAPIModule} from './pros_angular-client';
import {ApiModule as AuaAPIModule} from './aua_angular-client';
import {ApiModule as OscAPIModule} from './osc_angular-client';
import {ApiModule as IdPAPIModule} from './idp_angular-client';
import {ApiModule as BiloAPIModule} from './bilo_angular-client';

import {Configuration as LimaConfig} from './lima_angular-client';
import {Configuration as ProsConfig} from './pros_angular-client';
import {Configuration as AuaConfig} from './aua_angular-client';
import {Configuration as OscConfig} from './osc_angular-client';
import {Configuration as IdPConfig} from './idp_angular-client';
import {Configuration as BiloConfig} from './bilo_angular-client';

import {ConfigurationParameters as LimaConfigParams} from './lima_angular-client';
import {ConfigurationParameters as ProsConfigParams} from './pros_angular-client';
import {ConfigurationParameters as AuaConfigParams} from './aua_angular-client';
import {ConfigurationParameters as OscConfigParams} from './osc_angular-client';
import {ConfigurationParameters as IdPConfigParams} from './idp_angular-client';
import {ConfigurationParameters as BiloConfigParams} from './bilo_angular-client';

export function LIMAConfigFactory(): LimaConfig {
    const params: LimaConfigParams = {};
    return new LimaConfig(params);
}

export function PROSConfigFactory(): ProsConfig {
    const params: ProsConfigParams = {};
    return new ProsConfig(params);
}

export function AuAConfigFactory(): AuaConfig {
    const params: AuaConfigParams = {};
    return new AuaConfig(params);
}

export function OscConfigFactory(): OscConfig {
    const params: OscConfigParams = {};
    return new OscConfig(params);
}

export function IdPConfigFactory(): IdPConfig {
    const params: IdPConfigParams = {};
    return new IdPConfig(params);
}

export function BiloConfigFactory(): IdPConfig {
    const params: BiloConfigParams = {};
    return new BiloConfig(params);
}

@NgModule({
    declarations: [],
    imports: [
        LimaAPIModule.forRoot(LIMAConfigFactory),
        AuaAPIModule.forRoot(AuAConfigFactory),
        ProsAPIModule.forRoot(PROSConfigFactory),
        OscAPIModule.forRoot(OscConfigFactory),
        IdPAPIModule.forRoot(IdPConfigFactory),
        BiloAPIModule.forRoot(BiloConfigFactory)
    ]
})
export class ApiModule {
}
