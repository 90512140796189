import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthenticationModule} from './modules/authentication/authentication.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HeaderModule} from './modules/header/header.module';
import {LoginModule} from './modules/login/login.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTPGlobalResponse} from './interceptor/http-global-response.interceptor';
import {SharedModule} from './modules/shared/shared.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from './modules/material/material.module';
import {ExtendedSearchModule} from './modules/extended-search/extended-search.module';
import {ApiModule} from './modules/api/api.module';
import {MockRequestsInterceptor} from './interceptor/mock-requests.interceptor';
import {PopupConfirmationDialogComponent} from './modules/shared/component/popup-confirmation-dialog/popup-confirmation-dialog.component';
import {PopupErrorDialogComponent} from './modules/shared/component/popup-error-dialog/popup-error-dialog.component';
import {SettingsHttpService} from './service/settings.http.service';
import {SiteLayoutComponent} from './_layout/site-layout/site-layout.component';

/**
 * Starts requesting the settings.json file for the app.
 * @param settingsHttpService to get the settings file
 */
export function app_Init(settingsHttpService: SettingsHttpService) {
    return () => settingsHttpService.initializeApp();
}

/**
 *  Module Imports
 */
@NgModule({
    declarations: [
        AppComponent,
        SiteLayoutComponent
    ],
    imports: [
        MaterialModule,
        ApiModule,
        FlexLayoutModule,
        SharedModule,
        HttpClientModule,
        HeaderModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AuthenticationModule,
        LoginModule,
        ExtendedSearchModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HTTPGlobalResponse,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MockRequestsInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: app_Init,
            deps: [SettingsHttpService],
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    entryComponents: [PopupErrorDialogComponent, PopupConfirmationDialogComponent]
})

export class AppModule {
}
