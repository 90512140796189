import { share } from 'rxjs/operators';
import { AuaApiService } from '../../../service/aua-api.service';
import { IdpApiService } from '../../../service/idp-api.service';
import { OscApiService } from '../../../service/osc-api.service';
import { Coupon as COUPON } from '../coupon.dict';
import * as i0 from "@angular/core";
import * as i1 from "../../../service/idp-api.service";
import * as i2 from "../../../service/osc-api.service";
import * as i3 from "../../../service/aua-api.service";
var CouponDetailsService = /** @class */ (function () {
    function CouponDetailsService(idpApiService, oscApiService, auaApiService) {
        this.idpApiService = idpApiService;
        this.oscApiService = oscApiService;
        this.auaApiService = auaApiService;
        this.couponSearchStack = [];
    }
    /**
     * Clear couponSearchStack
     */
    CouponDetailsService.prototype.clearCoupons = function () {
        this.couponSearchStack = [];
        sessionStorage.removeItem('couponSearchStack');
    };
    /**
     * Get User Details
     * @param subject to get user data
     */
    CouponDetailsService.prototype.getUser = function (subject) {
        // share() makes sure, that the requested data is shared among the subscriber
        return this.idpApiService.getUser(subject).pipe(share());
    };
    /**
     * Returns human readable string to given couponState
     * @param couponState to check
     */
    CouponDetailsService.prototype.getCouponCodeString = function (couponState) {
        var stateTranslation = '';
        switch (couponState) {
            case COUPON.STATE.ACTIVE:
                stateTranslation = 'Aktiv';
                break;
            case COUPON.STATE.VALID:
                stateTranslation = 'Gültig';
                break;
            case COUPON.STATE.ASSIGNED:
                stateTranslation = 'Eingelöst';
                break;
            case COUPON.STATE.WITHDRAWN:
                stateTranslation = 'Zurückgezogen';
                break;
        }
        return stateTranslation;
    };
    /**
     * Change state of coupon
     * @param couponCode
     * @param couponState
     * @param subject
     * @param orderSystemDTO
     */
    CouponDetailsService.prototype.changeState = function (couponCode, couponState, subject, orderSystemDTO) {
        return this.oscApiService.changeState(couponCode, couponState, subject, orderSystemDTO);
    };
    /**
     *
     * @param couponChangeDTO
     */
    CouponDetailsService.prototype.changeCoupon = function (couponChangeDTO) {
        return this.oscApiService.changeCoupon(couponChangeDTO);
    };
    /**
     * Snyc order for user
     * @param subject to sync
     */
    CouponDetailsService.prototype.orderSyncForUsers = function (subject) {
        return this.oscApiService.orderSyncForUser(subject);
    };
    /**
     * Using coupon search to get to coupon informations
     *
     * @param couponCode
     * @param quickSearch
     */
    CouponDetailsService.prototype.couponSearch = function (couponCode, quickSearch) {
        if (quickSearch === void 0) { quickSearch = true; }
        return this.oscApiService.quickSearchCouponCode(couponCode, quickSearch);
    };
    CouponDetailsService.ngInjectableDef = i0.defineInjectable({ factory: function CouponDetailsService_Factory() { return new CouponDetailsService(i0.inject(i1.IdpApiService), i0.inject(i2.OscApiService), i0.inject(i3.AuaApiService)); }, token: CouponDetailsService, providedIn: "root" });
    return CouponDetailsService;
}());
export { CouponDetailsService };
