import {Injectable} from '@angular/core';
import {
    ClientService,
    OperatorService,
    SetEmailDtoDetailResponse,
    SetEmailResponse,
    UserDeleteRequest, UserDeleteResponse, UserGetRequest, UserGetResponse,
    UserSetEmailRequest
} from '../modules/api/idp_angular-client';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MessengerService} from '../modules/shared/service/messenger.service';

@Injectable({
    providedIn: 'root'
})
export class IdpApiService {

    constructor(private idPClientService: ClientService, private idPOperatorService: OperatorService, private messengerService: MessengerService) {
    }

    /**
     * Triggers reset email.
     * @param email to reset
     */
    resetPassword(email: string): Observable<any> {
        return this.idPClientService.resetPasswordRequest(email);
    }

    /**
     * Deletes user in IDP.
     * @param subject to delete
     */
    deleteUser(subject: string): Observable<UserDeleteResponse> {
        const userDeleteRequest: UserDeleteRequest[] = [{
            subject
        }];
        return this.idPOperatorService.deleteUser('', '', '', userDeleteRequest);
    }

    /**
     * Gets the user from IDP
     * @param subject
     */
    getUser(subject: string): Observable<UserGetResponse> {
        const getUserRequest: UserGetRequest[] = [{
            subject
        }];

        return this.idPOperatorService.getUser('', '', '', getUserRequest);
    }

    /**
     * Set new user email
     * @param subject to change
     * @param email new email
     */
    setEmail(subject: string, email: string): Observable<SetEmailResponse> {

        const setEmailRequest: UserSetEmailRequest[] = [{
            subject,
            email
        }];

        return this.idPClientService.setEmail('', '', '', setEmailRequest).pipe(map((setEmailResponse) => {

            if (setEmailResponse.global_response.result_state === 'WARN') {
                const detailResponses: Array<SetEmailDtoDetailResponse> = setEmailResponse.user_change_email_detail_responses;

                for (const detailResponse of detailResponses) {
                    this.messengerService.sendNotification(detailResponse.detail_result_response.result_code, 'IDP | setEmail');
                }
            }

            return setEmailResponse;
        }));
    }


}
