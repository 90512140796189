import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AuthService} from '../../../authentication/services/auth.service';
import {UserDTO} from '../../../api/aua_angular-client';
import {Observable} from 'rxjs';
import {delay, finalize, map} from 'rxjs/operators';
import {TabService} from '../../service/tab.service';
import {CouponDetailsService} from '../../../coupon-details/service/coupon-details.service';

@Component({
    selector: 'app-user-information',
    templateUrl: './user-information.component.html',
    styleUrls: ['./user-information.component.scss']
})
export class UserInformationComponent implements OnInit, AfterViewInit {

    // User from AUA
    auaUser$: Observable<UserDTO>;

    // Loading state of User Information
    isLoading: boolean;

    constructor(private authService: AuthService,
                private couponDetailsService: CouponDetailsService,
                private tabService: TabService) {
    }

    ngOnInit() {
        this.isLoading = true;
    }

    ngAfterViewInit() {
        this.getUser();
    }

    /**
     * Gets OCID User and resolves subject in AuA
     */
    getUser(): void {
        this.auaUser$ = this.authService.getAuAUser$().pipe(
            delay(0),
            finalize(() => this.isLoading = false),
            map((response) => {
                return response;
            })
        );
    }

    /**
     * Starts signout and navigates back to login page
     */
    handleLogOutClick() {
        this.couponDetailsService.clearCoupons();
        this.tabService.clearTabs();
        this.authService.startSignOut('AUTH_USER_LOGOUT');
    }

    /**
     * Open admin menu
     */
    handleAdminClick() {

    }
}
