import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {Settings} from '../models/settings';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SettingsHttpService {

    private promiseResolved = new BehaviorSubject<any>(this.settingsService.settings);

    constructor(private http: HttpClient, private settingsService: SettingsService) {
    }

    /**
     * Requests the settings file and sends out the response.
     */
    initializeApp(): Promise<any> {
        return new Promise(
            (resolve) => {
                this.http.get('assets/settings/settings.json')
                    .toPromise()
                    .then(response => {
                            // Stores the loaded settings in the service
                            this.settingsService.settings = response as Settings;
                            this.promiseResolved.next(this.settingsService.settings);
                            resolve();
                        }
                    );
            }
        );
    }

    /**
     * Subject to get new settings
     */
    getSettingsSubject(): BehaviorSubject<any> {
        return this.promiseResolved;
    }

}
