import {Component, OnInit, ViewChild} from '@angular/core';
import {MobileFlyoutComponent} from '../mobile-flyout/mobile-flyout.component';
import {environment} from '../../../../../environments/environment';
import {SettingsService} from '../../../../service/settings.service';

@Component({
    selector: 'app-mobile-header',
    templateUrl: './mobile-header.component.html',
    styleUrls: ['./mobile-header.component.scss']
})


export class MobileHeaderComponent implements OnInit {
    @ViewChild(MobileFlyoutComponent) flyout: MobileFlyoutComponent;
    logoURL: string;

    constructor(private settingsService: SettingsService) {
        this.logoURL = settingsService.settings.theme.logoURL;
    }

    ngOnInit() {}

    onMoblieMenuClick() {
        this.flyout.toggle();
    }
}
