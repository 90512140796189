import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../authentication/services/auth.service';
import {RoutingService} from '../../../../service/routing.service';
import {SettingsHttpService} from '../../../../service/settings.http.service';
import {LoginService} from '../../../../service/login.service';
import resultCodes from '../../../../app.resultCodes';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    // default
    public logoURL = '/assets/ionesoft_HelpDesk.png';

    public logoutReason = '';

    constructor(private router: Router,
                private routingService: RoutingService,
                private loginService: LoginService,
                private settingsHTTPService: SettingsHttpService,
                private authService: AuthService) {
    }

    ngOnInit(): void {
        this.requestAppSettings();
        this.getLogoutReason();
    }

    getLogoutReason() {
        const reason = this.loginService.getLogoutReason();
        if (reason !== 'AUTH_USER_LOGOUT') {
            this.logoutReason = resultCodes.getMessage(reason);
        }
    }

    /**
     * Requests app settings from SettingsHttpService
     */
    requestAppSettings() {
        if (this.settingsHTTPService) {
            this.settingsHTTPService.getSettingsSubject().subscribe((settings) => {
                if (typeof settings !== 'undefined') {
                    this.logoURL = settings.theme.logoURL;
                }
            });
        }
    }

    onLoginClick() {
        this.authService.startAuthentication();
    }
}
