import {NgModule} from '@angular/core';
import {AuthCallbackComponent} from './component/auth-callback/auth-callback.component';
import {MatProgressBarModule} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    declarations: [
        AuthCallbackComponent
    ],
    imports: [
        MatProgressBarModule,
        FlexLayoutModule
    ]
})
export class AuthenticationModule {
}
