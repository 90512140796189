/**
 * ioneaccess License Manager (LIMA) API
 * API for ioneacces License Manager (LIMA)
 *
 * OpenAPI spec version: 1.0.74
 * Contact: florian.mischler@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CacheFlushResponse1 } from '../model/cacheFlushResponse1';
import { LicenseOrderDeleteRequest1 } from '../model/licenseOrderDeleteRequest1';
import { LicenseOrderDeleteResponse1 } from '../model/licenseOrderDeleteResponse1';
import { LicenseOrderGetForUserRequest1 } from '../model/licenseOrderGetForUserRequest1';
import { LicenseOrderGetForUserResponse1 } from '../model/licenseOrderGetForUserResponse1';
import { LicenseOrderSaveRequest1 } from '../model/licenseOrderSaveRequest1';
import { LicenseOrderSaveResponse1 } from '../model/licenseOrderSaveResponse1';
import { LicenseOverrideDeleteRequest1 } from '../model/licenseOverrideDeleteRequest1';
import { LicenseOverrideDeleteResponse1 } from '../model/licenseOverrideDeleteResponse1';
import { LicenseOverrideSaveRequest1 } from '../model/licenseOverrideSaveRequest1';
import { LicenseOverrideSaveResponse1 } from '../model/licenseOverrideSaveResponse1';
import { LicensePruneAllLicensesFromUserRequest1 } from '../model/licensePruneAllLicensesFromUserRequest1';
import { LicensePruneAllLicensesFromUserResponse1 } from '../model/licensePruneAllLicensesFromUserResponse1';
import { ProductSaveRequest1 } from '../model/productSaveRequest1';
import { ProductSaveResponse1 } from '../model/productSaveResponse1';
import { ProductVerifyUserAccessRequest1 } from '../model/productVerifyUserAccessRequest1';
import { ProductVerifyUserAccessResponse1 } from '../model/productVerifyUserAccessResponse1';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {SettingsHttpService} from '../../../../service/settings.http.service';
import {User} from 'oidc-client';


@Injectable()
export class LIMASystem1Service {

    protected basePath = 'https://igate.beook.work/lima/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, protected httpService: SettingsHttpService, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
        if (httpService) {
            httpService.getSettingsSubject().subscribe((settings) => {
                this.basePath = settings.basePathLIMA;
                if (settings.oicdStorageKey) {
                    const user: User = JSON.parse(sessionStorage.getItem(settings.oicdStorageKey));
                    this.configuration.apiKeys = {
                        Authorization: user ? user.id_token : null
                    };
                }
            });
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Flush cache.
     * Provides ability to flush entire cache
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cacheFlush(X_Ia_Correlation_Id: string, observe?: 'body', reportProgress?: boolean): Observable<CacheFlushResponse1>;
    public cacheFlush(X_Ia_Correlation_Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CacheFlushResponse1>>;
    public cacheFlush(X_Ia_Correlation_Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CacheFlushResponse1>>;
    public cacheFlush(X_Ia_Correlation_Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling cacheFlush.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CacheFlushResponse1>(`${this.basePath}/cache/flush`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete license orders
     * Delete license orders
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param LicenseOrderDeleteRequest1 Parameters to delete license orders for user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licenseOrderDelete(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOrderDeleteRequest1: LicenseOrderDeleteRequest1, observe?: 'body', reportProgress?: boolean): Observable<LicenseOrderDeleteResponse1>;
    public licenseOrderDelete(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOrderDeleteRequest1: LicenseOrderDeleteRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicenseOrderDeleteResponse1>>;
    public licenseOrderDelete(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOrderDeleteRequest1: LicenseOrderDeleteRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicenseOrderDeleteResponse1>>;
    public licenseOrderDelete(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOrderDeleteRequest1: LicenseOrderDeleteRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling licenseOrderDelete.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling licenseOrderDelete.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling licenseOrderDelete.');
        }

        if (LicenseOrderDeleteRequest1 === null || LicenseOrderDeleteRequest1 === undefined) {
            throw new Error('Required parameter LicenseOrderDeleteRequest1 was null or undefined when calling licenseOrderDelete.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LicenseOrderDeleteResponse1>(`${this.basePath}/licenseOrder/delete`,
            LicenseOrderDeleteRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get license orders of given user
     * Get license order of given user w/ optional ordersystem
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param LicenseOrderGetForUserRequest1 Get license orders for user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licenseOrderGetForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOrderGetForUserRequest1: LicenseOrderGetForUserRequest1, observe?: 'body', reportProgress?: boolean): Observable<LicenseOrderGetForUserResponse1>;
    public licenseOrderGetForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOrderGetForUserRequest1: LicenseOrderGetForUserRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicenseOrderGetForUserResponse1>>;
    public licenseOrderGetForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOrderGetForUserRequest1: LicenseOrderGetForUserRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicenseOrderGetForUserResponse1>>;
    public licenseOrderGetForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOrderGetForUserRequest1: LicenseOrderGetForUserRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling licenseOrderGetForUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling licenseOrderGetForUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling licenseOrderGetForUser.');
        }

        if (LicenseOrderGetForUserRequest1 === null || LicenseOrderGetForUserRequest1 === undefined) {
            throw new Error('Required parameter LicenseOrderGetForUserRequest1 was null or undefined when calling licenseOrderGetForUser.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LicenseOrderGetForUserResponse1>(`${this.basePath}/licenseOrder/getForUser`,
            LicenseOrderGetForUserRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create &amp; update license orders for user
     * Create &amp; update license orders for user
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param LicenseOrderSaveRequest1 Parameters to create &amp; update license orders for user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licenseOrderSave(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOrderSaveRequest1: LicenseOrderSaveRequest1, observe?: 'body', reportProgress?: boolean): Observable<LicenseOrderSaveResponse1>;
    public licenseOrderSave(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOrderSaveRequest1: LicenseOrderSaveRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicenseOrderSaveResponse1>>;
    public licenseOrderSave(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOrderSaveRequest1: LicenseOrderSaveRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicenseOrderSaveResponse1>>;
    public licenseOrderSave(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOrderSaveRequest1: LicenseOrderSaveRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling licenseOrderSave.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling licenseOrderSave.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling licenseOrderSave.');
        }

        if (LicenseOrderSaveRequest1 === null || LicenseOrderSaveRequest1 === undefined) {
            throw new Error('Required parameter LicenseOrderSaveRequest1 was null or undefined when calling licenseOrderSave.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LicenseOrderSaveResponse1>(`${this.basePath}/licenseOrder/save`,
            LicenseOrderSaveRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a licenseOverride for a given license
     * Delete a licenseOverride concerning a license
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param LicenseOverrideDeleteRequest1 Delete license override request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licenseOverrideDelete(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOverrideDeleteRequest1: LicenseOverrideDeleteRequest1, observe?: 'body', reportProgress?: boolean): Observable<LicenseOverrideDeleteResponse1>;
    public licenseOverrideDelete(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOverrideDeleteRequest1: LicenseOverrideDeleteRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicenseOverrideDeleteResponse1>>;
    public licenseOverrideDelete(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOverrideDeleteRequest1: LicenseOverrideDeleteRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicenseOverrideDeleteResponse1>>;
    public licenseOverrideDelete(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOverrideDeleteRequest1: LicenseOverrideDeleteRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling licenseOverrideDelete.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling licenseOverrideDelete.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling licenseOverrideDelete.');
        }

        if (LicenseOverrideDeleteRequest1 === null || LicenseOverrideDeleteRequest1 === undefined) {
            throw new Error('Required parameter LicenseOverrideDeleteRequest1 was null or undefined when calling licenseOverrideDelete.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LicenseOverrideDeleteResponse1>(`${this.basePath}/licenseOverride/delete`,
            LicenseOverrideDeleteRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save a licenseOverride for a given license
     * Save a licenseOverride concerning a license to override consolidated values
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param LicenseOverrideSaveRequest1 Save license override request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licenseOverrideSave(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOverrideSaveRequest1: LicenseOverrideSaveRequest1, observe?: 'body', reportProgress?: boolean): Observable<LicenseOverrideSaveResponse1>;
    public licenseOverrideSave(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOverrideSaveRequest1: LicenseOverrideSaveRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicenseOverrideSaveResponse1>>;
    public licenseOverrideSave(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOverrideSaveRequest1: LicenseOverrideSaveRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicenseOverrideSaveResponse1>>;
    public licenseOverrideSave(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseOverrideSaveRequest1: LicenseOverrideSaveRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling licenseOverrideSave.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling licenseOverrideSave.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling licenseOverrideSave.');
        }

        if (LicenseOverrideSaveRequest1 === null || LicenseOverrideSaveRequest1 === undefined) {
            throw new Error('Required parameter LicenseOverrideSaveRequest1 was null or undefined when calling licenseOverrideSave.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LicenseOverrideSaveResponse1>(`${this.basePath}/licenseOverride/save`,
            LicenseOverrideSaveRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete all licenses from given user
     * Delete all licenses from given user.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param LicensePruneAllLicensesFromUserRequest1 Containing user subject for which to remove all licenses
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licensePruneAllLicensesFromUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicensePruneAllLicensesFromUserRequest1: LicensePruneAllLicensesFromUserRequest1, observe?: 'body', reportProgress?: boolean): Observable<LicensePruneAllLicensesFromUserResponse1>;
    public licensePruneAllLicensesFromUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicensePruneAllLicensesFromUserRequest1: LicensePruneAllLicensesFromUserRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicensePruneAllLicensesFromUserResponse1>>;
    public licensePruneAllLicensesFromUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicensePruneAllLicensesFromUserRequest1: LicensePruneAllLicensesFromUserRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicensePruneAllLicensesFromUserResponse1>>;
    public licensePruneAllLicensesFromUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicensePruneAllLicensesFromUserRequest1: LicensePruneAllLicensesFromUserRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling licensePruneAllLicensesFromUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling licensePruneAllLicensesFromUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling licensePruneAllLicensesFromUser.');
        }

        if (LicensePruneAllLicensesFromUserRequest1 === null || LicensePruneAllLicensesFromUserRequest1 === undefined) {
            throw new Error('Required parameter LicensePruneAllLicensesFromUserRequest1 was null or undefined when calling licensePruneAllLicensesFromUser.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LicensePruneAllLicensesFromUserResponse1>(`${this.basePath}/license/pruneAllLicensesFromUser`,
            LicensePruneAllLicensesFromUserRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create &amp; update products
     * Create &amp; update products in LIMA products table. Original data is from PROS.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param ProductSaveRequest1 Parameters to save products
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productSave(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductSaveRequest1: ProductSaveRequest1, observe?: 'body', reportProgress?: boolean): Observable<ProductSaveResponse1>;
    public productSave(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductSaveRequest1: ProductSaveRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductSaveResponse1>>;
    public productSave(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductSaveRequest1: ProductSaveRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductSaveResponse1>>;
    public productSave(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductSaveRequest1: ProductSaveRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling productSave.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling productSave.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling productSave.');
        }

        if (ProductSaveRequest1 === null || ProductSaveRequest1 === undefined) {
            throw new Error('Required parameter ProductSaveRequest1 was null or undefined when calling productSave.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductSaveResponse1>(`${this.basePath}/product/save`,
            ProductSaveRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify if a user has access with given configuration on a product
     * The asked product can be itself or the parent product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param ProductVerifyUserAccessRequest1 Verify if a user has right to access data from given product
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productVerifyUserAccess(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductVerifyUserAccessRequest1: ProductVerifyUserAccessRequest1, observe?: 'body', reportProgress?: boolean): Observable<ProductVerifyUserAccessResponse1>;
    public productVerifyUserAccess(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductVerifyUserAccessRequest1: ProductVerifyUserAccessRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductVerifyUserAccessResponse1>>;
    public productVerifyUserAccess(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductVerifyUserAccessRequest1: ProductVerifyUserAccessRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductVerifyUserAccessResponse1>>;
    public productVerifyUserAccess(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductVerifyUserAccessRequest1: ProductVerifyUserAccessRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling productVerifyUserAccess.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling productVerifyUserAccess.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling productVerifyUserAccess.');
        }

        if (ProductVerifyUserAccessRequest1 === null || ProductVerifyUserAccessRequest1 === undefined) {
            throw new Error('Required parameter ProductVerifyUserAccessRequest1 was null or undefined when calling productVerifyUserAccess.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductVerifyUserAccessResponse1>(`${this.basePath}/product/verifyUserAccess`,
            ProductVerifyUserAccessRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
