/**
 * ioneaccess Authentication and User Aggregator (AUA) API
 * API for ioneacces Authenticator and User Aggregator (AUA). This document does not cover the OpenID Connect part!
 *
 * OpenAPI spec version: 1.1.2
 * Contact: christian.schlatter@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AWSCognitoTokenResponse } from '../model/aWSCognitoTokenResponse';
import { AttachPermissionDTO } from '../model/attachPermissionDTO';
import { CheckMembershipRequest } from '../model/checkMembershipRequest';
import { CreatePropertyTemplateRequest } from '../model/createPropertyTemplateRequest';
import { DetachPermissionDTO } from '../model/detachPermissionDTO';
import { GroupListResponse } from '../model/groupListResponse';
import { MigrationCallbackDTO } from '../model/migrationCallbackDTO';
import { PermissionAttachedResponse } from '../model/permissionAttachedResponse';
import { PermissionCreateResponse } from '../model/permissionCreateResponse';
import { PermissionDTO } from '../model/permissionDTO';
import { PermissionDeleteResponse } from '../model/permissionDeleteResponse';
import { PermissionDetachedResponse } from '../model/permissionDetachedResponse';
import { PermissionListResponse } from '../model/permissionListResponse';
import { PermissionSetResponse } from '../model/permissionSetResponse';
import { PropertyCreateTemplatesResponse } from '../model/propertyCreateTemplatesResponse';
import { PropertyGetTemplatesResponse } from '../model/propertyGetTemplatesResponse';
import { PropertyToggleTemplatesResponse } from '../model/propertyToggleTemplatesResponse';
import { ProviderListResponse } from '../model/providerListResponse';
import { ResolveRoleResponse } from '../model/resolveRoleResponse';
import { RoleCreateResponse } from '../model/roleCreateResponse';
import { RoleDTO } from '../model/roleDTO';
import { RoleDeleteResponse } from '../model/roleDeleteResponse';
import { RoleListResponse } from '../model/roleListResponse';
import { SetPermissionDTO } from '../model/setPermissionDTO';
import { TogglePropertyTemplateRequest } from '../model/togglePropertyTemplateRequest';
import { UserCheckMemebershipResponse } from '../model/userCheckMemebershipResponse';
import { UserDTO } from '../model/userDTO';
import { UserDeleteIdentityRequest } from '../model/userDeleteIdentityRequest';
import { UserDeleteIdentityResponse } from '../model/userDeleteIdentityResponse';
import { UserDeleteResponse } from '../model/userDeleteResponse';
import { UserExtendedSearchRequest } from '../model/userExtendedSearchRequest';
import { UserExtendedSearchResponse } from '../model/userExtendedSearchResponse';
import { UserExternalSearchRequest } from '../model/userExternalSearchRequest';
import { UserExternalSearchResponse } from '../model/userExternalSearchResponse';
import { UserGetForProviderRequest } from '../model/userGetForProviderRequest';
import { UserGetForProviderResponse } from '../model/userGetForProviderResponse';
import { UserGetGroupsGlobalResponse } from '../model/userGetGroupsGlobalResponse';
import { UserGetIdentitiesResponse } from '../model/userGetIdentitiesResponse';
import { UserGetPropertiesResponse } from '../model/userGetPropertiesResponse';
import { UserGetRequest } from '../model/userGetRequest';
import { UserGetResponse } from '../model/userGetResponse';
import { UserGetRolesResponse } from '../model/userGetRolesResponse';
import { UserLinkRequest } from '../model/userLinkRequest';
import { UserLinkResponse } from '../model/userLinkResponse';
import { UserQuickSearchRequest } from '../model/userQuickSearchRequest';
import { UserQuickSearchResponse } from '../model/userQuickSearchResponse';
import { UserSetEmailRequest } from '../model/userSetEmailRequest';
import { UserSetEmailResponse } from '../model/userSetEmailResponse';
import { UserSetGlobalResponse } from '../model/userSetGlobalResponse';
import { UserSetGroupsRequest } from '../model/userSetGroupsRequest';
import { UserSetPropertiesResponse } from '../model/userSetPropertiesResponse';
import { UserSetPropertyRequest } from '../model/userSetPropertyRequest';
import { UserSetResponse } from '../model/userSetResponse';
import { UserSetRolesRequest } from '../model/userSetRolesRequest';

import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {Configuration} from '../configuration';
import {SettingsHttpService} from '../../../../service/settings.http.service';
import {User} from 'oidc-client';


@Injectable()
export class AUAClientService {

    protected basePath = 'https://igate.ionedev.com/aua/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, protected httpService: SettingsHttpService, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
        if (httpService) {
            httpService.getSettingsSubject().subscribe((settings) => {
                if (typeof settings !== 'undefined') {
                    this.basePath = settings.basePathAuA;
                    if (settings.oicdStorageKey) {
                        const user: User = JSON.parse(sessionStorage.getItem(settings.oicdStorageKey));
                        this.configuration.apiKeys = {
                            Authorization: user ? user.id_token : null
                        };
                    }
                }
            });
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Attach permission to existing role
     * Attach permissions to role. Permissions &amp; roles must exist.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param attachPermissionRequests Array of roles with list of permissions to attach
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attachPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, attachPermissionRequests: Array<AttachPermissionDTO>, observe?: 'body', reportProgress?: boolean): Observable<PermissionAttachedResponse>;
    public attachPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, attachPermissionRequests: Array<AttachPermissionDTO>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PermissionAttachedResponse>>;
    public attachPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, attachPermissionRequests: Array<AttachPermissionDTO>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PermissionAttachedResponse>>;
    public attachPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, attachPermissionRequests: Array<AttachPermissionDTO>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling attachPermissions.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling attachPermissions.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling attachPermissions.');
        }

        if (attachPermissionRequests === null || attachPermissionRequests === undefined) {
            throw new Error('Required parameter attachPermissionRequests was null or undefined when calling attachPermissions.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PermissionAttachedResponse>(`${this.basePath}/role/attachPermission`,
            attachPermissionRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check group membership
     * check group membership
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param checkMembershipRequests Check if caller is allowed to do action on subject
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkgroupMembership(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, checkMembershipRequests: CheckMembershipRequest, observe?: 'body', reportProgress?: boolean): Observable<UserCheckMemebershipResponse>;
    public checkgroupMembership(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, checkMembershipRequests: CheckMembershipRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserCheckMemebershipResponse>>;
    public checkgroupMembership(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, checkMembershipRequests: CheckMembershipRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserCheckMemebershipResponse>>;
    public checkgroupMembership(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, checkMembershipRequests: CheckMembershipRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling checkgroupMembership.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling checkgroupMembership.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling checkgroupMembership.');
        }

        if (checkMembershipRequests === null || checkMembershipRequests === undefined) {
            throw new Error('Required parameter checkMembershipRequests was null or undefined when calling checkgroupMembership.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserCheckMemebershipResponse>(`${this.basePath}/user/checkGroupMembership`,
            checkMembershipRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new set of permissions
     * Create a new set of permissions
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param permissionCreateRequests Array of roles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, permissionCreateRequests: Array<PermissionDTO>, observe?: 'body', reportProgress?: boolean): Observable<PermissionCreateResponse>;
    public createPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, permissionCreateRequests: Array<PermissionDTO>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PermissionCreateResponse>>;
    public createPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, permissionCreateRequests: Array<PermissionDTO>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PermissionCreateResponse>>;
    public createPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, permissionCreateRequests: Array<PermissionDTO>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createPermissions.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createPermissions.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createPermissions.');
        }

        if (permissionCreateRequests === null || permissionCreateRequests === undefined) {
            throw new Error('Required parameter permissionCreateRequests was null or undefined when calling createPermissions.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PermissionCreateResponse>(`${this.basePath}/permission/create`,
            permissionCreateRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new template properties
     * Create new template properties
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param templateCreateRequests Property template ro create
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPropertyTemplates(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, templateCreateRequests: CreatePropertyTemplateRequest, observe?: 'body', reportProgress?: boolean): Observable<PropertyCreateTemplatesResponse>;
    public createPropertyTemplates(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, templateCreateRequests: CreatePropertyTemplateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PropertyCreateTemplatesResponse>>;
    public createPropertyTemplates(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, templateCreateRequests: CreatePropertyTemplateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PropertyCreateTemplatesResponse>>;
    public createPropertyTemplates(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, templateCreateRequests: CreatePropertyTemplateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createPropertyTemplates.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createPropertyTemplates.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createPropertyTemplates.');
        }

        if (templateCreateRequests === null || templateCreateRequests === undefined) {
            throw new Error('Required parameter templateCreateRequests was null or undefined when calling createPropertyTemplates.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PropertyCreateTemplatesResponse>(`${this.basePath}/property/createTemplates`,
            templateCreateRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new roles and attach permissions
     * Create new roles and attach permissions. Permissions must exist.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param roles Array of roles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: Array<RoleDTO>, observe?: 'body', reportProgress?: boolean): Observable<RoleCreateResponse>;
    public createRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: Array<RoleDTO>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoleCreateResponse>>;
    public createRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: Array<RoleDTO>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoleCreateResponse>>;
    public createRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: Array<RoleDTO>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createRoles.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createRoles.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createRoles.');
        }

        if (roles === null || roles === undefined) {
            throw new Error('Required parameter roles was null or undefined when calling createRoles.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RoleCreateResponse>(`${this.basePath}/role/create`,
            roles,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete a identity from one specific user
     * delete a identity  from one specific user
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param userDeleteIdentityRequests User Get Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteIdentityForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userDeleteIdentityRequests: UserDeleteIdentityRequest, observe?: 'body', reportProgress?: boolean): Observable<UserDeleteIdentityResponse>;
    public deleteIdentityForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userDeleteIdentityRequests: UserDeleteIdentityRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDeleteIdentityResponse>>;
    public deleteIdentityForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userDeleteIdentityRequests: UserDeleteIdentityRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDeleteIdentityResponse>>;
    public deleteIdentityForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userDeleteIdentityRequests: UserDeleteIdentityRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling deleteIdentityForUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling deleteIdentityForUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling deleteIdentityForUser.');
        }

        if (userDeleteIdentityRequests === null || userDeleteIdentityRequests === undefined) {
            throw new Error('Required parameter userDeleteIdentityRequests was null or undefined when calling deleteIdentityForUser.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserDeleteIdentityResponse>(`${this.basePath}/user/deleteIdentity`,
            userDeleteIdentityRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a set of permissions
     * Delete a set of permissions
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param permissionDeleteRequests Array of permissions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, permissionDeleteRequests: Array<PermissionDTO>, observe?: 'body', reportProgress?: boolean): Observable<PermissionDeleteResponse>;
    public deletePermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, permissionDeleteRequests: Array<PermissionDTO>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PermissionDeleteResponse>>;
    public deletePermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, permissionDeleteRequests: Array<PermissionDTO>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PermissionDeleteResponse>>;
    public deletePermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, permissionDeleteRequests: Array<PermissionDTO>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling deletePermissions.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling deletePermissions.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling deletePermissions.');
        }

        if (permissionDeleteRequests === null || permissionDeleteRequests === undefined) {
            throw new Error('Required parameter permissionDeleteRequests was null or undefined when calling deletePermissions.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PermissionDeleteResponse>(`${this.basePath}/permission/delete`,
            permissionDeleteRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete roles, keep attached permissions
     * Delete roles, keep attached permissions
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param roles Array of roles to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<RoleDeleteResponse>;
    public deleteRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoleDeleteResponse>>;
    public deleteRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoleDeleteResponse>>;
    public deleteRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling deleteRoles.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling deleteRoles.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling deleteRoles.');
        }

        if (roles === null || roles === undefined) {
            throw new Error('Required parameter roles was null or undefined when calling deleteRoles.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RoleDeleteResponse>(`${this.basePath}/role/delete`,
            roles,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete IA users unscoped
     * Delete IA users unscoped
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param userDeleteRequests Array of delete user requests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUsers(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userDeleteRequests: Array<UserGetRequest>, observe?: 'body', reportProgress?: boolean): Observable<UserDeleteResponse>;
    public deleteUsers(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userDeleteRequests: Array<UserGetRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDeleteResponse>>;
    public deleteUsers(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userDeleteRequests: Array<UserGetRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDeleteResponse>>;
    public deleteUsers(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userDeleteRequests: Array<UserGetRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling deleteUsers.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling deleteUsers.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling deleteUsers.');
        }

        if (userDeleteRequests === null || userDeleteRequests === undefined) {
            throw new Error('Required parameter userDeleteRequests was null or undefined when calling deleteUsers.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserDeleteResponse>(`${this.basePath}/user/delete`,
            userDeleteRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Detach permission to existing role
     * Detach permissions to role. Permissions &amp; roles must exist.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param detachPermissionRequests Array of roles with list of permissions to detach
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detachPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, detachPermissionRequests: Array<DetachPermissionDTO>, observe?: 'body', reportProgress?: boolean): Observable<PermissionDetachedResponse>;
    public detachPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, detachPermissionRequests: Array<DetachPermissionDTO>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PermissionDetachedResponse>>;
    public detachPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, detachPermissionRequests: Array<DetachPermissionDTO>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PermissionDetachedResponse>>;
    public detachPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, detachPermissionRequests: Array<DetachPermissionDTO>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling detachPermissions.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling detachPermissions.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling detachPermissions.');
        }

        if (detachPermissionRequests === null || detachPermissionRequests === undefined) {
            throw new Error('Required parameter detachPermissionRequests was null or undefined when calling detachPermissions.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PermissionDetachedResponse>(`${this.basePath}/role/detachPermission`,
            detachPermissionRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Extended search for users by form data
     * Extended search for users
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param user Part of subject / givenName / familyName / email / provider / mandantGroup
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public extendedSearchUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserExtendedSearchRequest, observe?: 'body', reportProgress?: boolean): Observable<UserExtendedSearchResponse>;
    public extendedSearchUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserExtendedSearchRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserExtendedSearchResponse>>;
    public extendedSearchUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserExtendedSearchRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserExtendedSearchResponse>>;
    public extendedSearchUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserExtendedSearchRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling extendedSearchUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling extendedSearchUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling extendedSearchUser.');
        }

        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling extendedSearchUser.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserExtendedSearchResponse>(`${this.basePath}/user/extendedSearch`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search for users by external id and provider
     * Search for users by provider info
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param user External search by external id and provider
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public externalSearchUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserExternalSearchRequest, observe?: 'body', reportProgress?: boolean): Observable<UserExternalSearchResponse>;
    public externalSearchUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserExternalSearchRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserExternalSearchResponse>>;
    public externalSearchUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserExternalSearchRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserExternalSearchResponse>>;
    public externalSearchUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserExternalSearchRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling externalSearchUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling externalSearchUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling externalSearchUser.');
        }

        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling externalSearchUser.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserExternalSearchResponse>(`${this.basePath}/user/externalSearch`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Notify AUA about the finished cloud migration
     * Notify AUA about the finished cloud migration. Sets the corresponding properties on the migrated user.
     * @param X_Ia_Subject UUID of user
     * @param migrationCallback User infos for migration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public finalizeMigration(X_Ia_Subject: string, migrationCallback?: MigrationCallbackDTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public finalizeMigration(X_Ia_Subject: string, migrationCallback?: MigrationCallbackDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public finalizeMigration(X_Ia_Subject: string, migrationCallback?: MigrationCallbackDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public finalizeMigration(X_Ia_Subject: string, migrationCallback?: MigrationCallbackDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling finalizeMigration.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/user/finalizedCloudMigration`,
            migrationCallback,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get an AWS cognito identity token
     * Get an AWS cognito identity token for the specific user.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAwsCognitoIdentityToken(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'body', reportProgress?: boolean): Observable<AWSCognitoTokenResponse>;
    public getAwsCognitoIdentityToken(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AWSCognitoTokenResponse>>;
    public getAwsCognitoIdentityToken(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AWSCognitoTokenResponse>>;
    public getAwsCognitoIdentityToken(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getAwsCognitoIdentityToken.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getAwsCognitoIdentityToken.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getAwsCognitoIdentityToken.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<AWSCognitoTokenResponse>(`${this.basePath}/user/getAwsCognitoToken`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return identities from one specific user
     * Return propidentitieserties from one specific user
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param userGetIdentitesRequests User Get Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIdentitiesForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetIdentitesRequests: UserGetRequest, observe?: 'body', reportProgress?: boolean): Observable<UserGetIdentitiesResponse>;
    public getIdentitiesForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetIdentitesRequests: UserGetRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserGetIdentitiesResponse>>;
    public getIdentitiesForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetIdentitesRequests: UserGetRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserGetIdentitiesResponse>>;
    public getIdentitiesForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetIdentitesRequests: UserGetRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getIdentitiesForUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getIdentitiesForUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getIdentitiesForUser.');
        }

        if (userGetIdentitesRequests === null || userGetIdentitesRequests === undefined) {
            throw new Error('Required parameter userGetIdentitesRequests was null or undefined when calling getIdentitiesForUser.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserGetIdentitiesResponse>(`${this.basePath}/user/getIdentities`,
            userGetIdentitesRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get mandant groups for user
     * Get mandant groups user
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param userGetMandantGroupsRequests User get request for obtain groups
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetMandantGroupsRequests: UserGetRequest, observe?: 'body', reportProgress?: boolean): Observable<UserGetGroupsGlobalResponse>;
    public getMandantGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetMandantGroupsRequests: UserGetRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserGetGroupsGlobalResponse>>;
    public getMandantGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetMandantGroupsRequests: UserGetRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserGetGroupsGlobalResponse>>;
    public getMandantGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetMandantGroupsRequests: UserGetRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getMandantGroups.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getMandantGroups.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getMandantGroups.');
        }

        if (userGetMandantGroupsRequests === null || userGetMandantGroupsRequests === undefined) {
            throw new Error('Required parameter userGetMandantGroupsRequests was null or undefined when calling getMandantGroups.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserGetGroupsGlobalResponse>(`${this.basePath}/user/getMandantGroups`,
            userGetMandantGroupsRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return  properties from one specific user
     * Return  properties from one specific user
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param userGetPropertiesRequests User Get Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPropertiesForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetPropertiesRequests: UserGetRequest, observe?: 'body', reportProgress?: boolean): Observable<UserGetPropertiesResponse>;
    public getPropertiesForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetPropertiesRequests: UserGetRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserGetPropertiesResponse>>;
    public getPropertiesForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetPropertiesRequests: UserGetRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserGetPropertiesResponse>>;
    public getPropertiesForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetPropertiesRequests: UserGetRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getPropertiesForUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getPropertiesForUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getPropertiesForUser.');
        }

        if (userGetPropertiesRequests === null || userGetPropertiesRequests === undefined) {
            throw new Error('Required parameter userGetPropertiesRequests was null or undefined when calling getPropertiesForUser.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserGetPropertiesResponse>(`${this.basePath}/user/getProperties`,
            userGetPropertiesRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return template properties
     * Return template properties
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPropertyTemplates(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'body', reportProgress?: boolean): Observable<PropertyGetTemplatesResponse>;
    public getPropertyTemplates(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PropertyGetTemplatesResponse>>;
    public getPropertyTemplates(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PropertyGetTemplatesResponse>>;
    public getPropertyTemplates(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getPropertyTemplates.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getPropertyTemplates.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getPropertyTemplates.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<PropertyGetTemplatesResponse>(`${this.basePath}/property/listTemplates`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all providers
     * Get all providers
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProviders(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'body', reportProgress?: boolean): Observable<ProviderListResponse>;
    public getProviders(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProviderListResponse>>;
    public getProviders(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProviderListResponse>>;
    public getProviders(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getProviders.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getProviders.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getProviders.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ProviderListResponse>(`${this.basePath}/provider/list`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get roles from a specific user
     * Get roles from a specific user
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param roles Array of roles to set on user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: UserGetRequest, observe?: 'body', reportProgress?: boolean): Observable<UserGetRolesResponse>;
    public getRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: UserGetRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserGetRolesResponse>>;
    public getRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: UserGetRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserGetRolesResponse>>;
    public getRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: UserGetRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getRoles.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getRoles.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getRoles.');
        }

        if (roles === null || roles === undefined) {
            throw new Error('Required parameter roles was null or undefined when calling getRoles.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserGetRolesResponse>(`${this.basePath}/user/getRoles`,
            roles,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get users specified by subjects
     * Get users specified by subjects
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param userGetRequests Array of set property requests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsers(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetRequests: Array<UserGetRequest>, observe?: 'body', reportProgress?: boolean): Observable<UserGetResponse>;
    public getUsers(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetRequests: Array<UserGetRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserGetResponse>>;
    public getUsers(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetRequests: Array<UserGetRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserGetResponse>>;
    public getUsers(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetRequests: Array<UserGetRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getUsers.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getUsers.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getUsers.');
        }

        if (userGetRequests === null || userGetRequests === undefined) {
            throw new Error('Required parameter userGetRequests was null or undefined when calling getUsers.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserGetResponse>(`${this.basePath}/user/get`,
            userGetRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get users specified by subjects
     * Get users specified by subjects
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param userGetForProviderRequests Array of set property requests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersForProvider(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetForProviderRequests: Array<UserGetForProviderRequest>, observe?: 'body', reportProgress?: boolean): Observable<UserGetForProviderResponse>;
    public getUsersForProvider(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetForProviderRequests: Array<UserGetForProviderRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserGetForProviderResponse>>;
    public getUsersForProvider(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetForProviderRequests: Array<UserGetForProviderRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserGetForProviderResponse>>;
    public getUsersForProvider(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userGetForProviderRequests: Array<UserGetForProviderRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getUsersForProvider.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getUsersForProvider.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getUsersForProvider.');
        }

        if (userGetForProviderRequests === null || userGetForProviderRequests === undefined) {
            throw new Error('Required parameter userGetForProviderRequests was null or undefined when calling getUsersForProvider.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserGetForProviderResponse>(`${this.basePath}/user/getForProvider`,
            userGetForProviderRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Link IA identitites
     * Link two or more IA identitites to a single IA identity. All IDs are merged with the oldest IA identity.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param uids Array with IA tokens to merge
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public linkIDs(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, uids?: UserLinkRequest, observe?: 'body', reportProgress?: boolean): Observable<UserLinkResponse>;
    public linkIDs(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, uids?: UserLinkRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserLinkResponse>>;
    public linkIDs(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, uids?: UserLinkRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserLinkResponse>>;
    public linkIDs(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, uids?: UserLinkRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling linkIDs.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling linkIDs.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling linkIDs.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserLinkResponse>(`${this.basePath}/user/link`,
            uids,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all mandant group names
     * Get all defined mandant group names
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'body', reportProgress?: boolean): Observable<GroupListResponse>;
    public listGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GroupListResponse>>;
    public listGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GroupListResponse>>;
    public listGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling listGroups.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling listGroups.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling listGroups.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<GroupListResponse>(`${this.basePath}/group/list`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get all defined permissions
     * Get all defined permissions
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'body', reportProgress?: boolean): Observable<PermissionListResponse>;
    public listPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PermissionListResponse>>;
    public listPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PermissionListResponse>>;
    public listPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling listPermissions.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling listPermissions.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling listPermissions.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<PermissionListResponse>(`${this.basePath}/permission/list`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all roles
     * Get all defined roles
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'body', reportProgress?: boolean): Observable<RoleListResponse>;
    public listRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoleListResponse>>;
    public listRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoleListResponse>>;
    public listRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling listRoles.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling listRoles.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling listRoles.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<RoleListResponse>(`${this.basePath}/role/list`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Quick search for users
     * Quick search for users
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param user Part of email / subject / name or creation / provider date to search for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quickSearchUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserQuickSearchRequest, observe?: 'body', reportProgress?: boolean): Observable<UserQuickSearchResponse>;
    public quickSearchUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserQuickSearchRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserQuickSearchResponse>>;
    public quickSearchUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserQuickSearchRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserQuickSearchResponse>>;
    public quickSearchUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, user: UserQuickSearchRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling quickSearchUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling quickSearchUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling quickSearchUser.');
        }

        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling quickSearchUser.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserQuickSearchResponse>(`${this.basePath}/user/quickSearch`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get permissions for roles
     * Get permissions for roles
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param roles Array of roles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resolveRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<ResolveRoleResponse>;
    public resolveRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResolveRoleResponse>>;
    public resolveRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResolveRoleResponse>>;
    public resolveRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling resolveRoles.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling resolveRoles.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling resolveRoles.');
        }

        if (roles === null || roles === undefined) {
            throw new Error('Required parameter roles was null or undefined when calling resolveRoles.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResolveRoleResponse>(`${this.basePath}/permission/resolveRoles`,
            roles,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set email on one specific user
     * Set email on one specific user.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param roles Array of roles to set on user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setEmail(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: UserSetEmailRequest, observe?: 'body', reportProgress?: boolean): Observable<UserSetEmailResponse>;
    public setEmail(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: UserSetEmailRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSetEmailResponse>>;
    public setEmail(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: UserSetEmailRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSetEmailResponse>>;
    public setEmail(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: UserSetEmailRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling setEmail.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling setEmail.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling setEmail.');
        }

        if (roles === null || roles === undefined) {
            throw new Error('Required parameter roles was null or undefined when calling setEmail.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserSetEmailResponse>(`${this.basePath}/user/setEmail`,
            roles,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set mandant groups on specific user
     * Set mandant groups on specific user. Existing groups will be replaced. Not existing groups will be created. Either all groups can be set, or the request fails.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param userSetMandantGroupRequest Array of mandant groups with membership types to set on user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setMandantGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userSetMandantGroupRequest: UserSetGroupsRequest, observe?: 'body', reportProgress?: boolean): Observable<UserSetGlobalResponse>;
    public setMandantGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userSetMandantGroupRequest: UserSetGroupsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSetGlobalResponse>>;
    public setMandantGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userSetMandantGroupRequest: UserSetGroupsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSetGlobalResponse>>;
    public setMandantGroups(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userSetMandantGroupRequest: UserSetGroupsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling setMandantGroups.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling setMandantGroups.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling setMandantGroups.');
        }

        if (userSetMandantGroupRequest === null || userSetMandantGroupRequest === undefined) {
            throw new Error('Required parameter userSetMandantGroupRequest was null or undefined when calling setMandantGroups.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserSetGlobalResponse>(`${this.basePath}/user/setMandantGroups`,
            userSetMandantGroupRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set permission to existing role
     * Set permissions to role. Permissions &amp; roles must exist.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param setPermissionRequests Array of roles with list of permissions to attach
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, setPermissionRequests: Array<SetPermissionDTO>, observe?: 'body', reportProgress?: boolean): Observable<PermissionSetResponse>;
    public setPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, setPermissionRequests: Array<SetPermissionDTO>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PermissionSetResponse>>;
    public setPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, setPermissionRequests: Array<SetPermissionDTO>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PermissionSetResponse>>;
    public setPermissions(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, setPermissionRequests: Array<SetPermissionDTO>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling setPermissions.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling setPermissions.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling setPermissions.');
        }

        if (setPermissionRequests === null || setPermissionRequests === undefined) {
            throw new Error('Required parameter setPermissionRequests was null or undefined when calling setPermissions.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PermissionSetResponse>(`${this.basePath}/role/setPermissions`,
            setPermissionRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set properties on one specific user
     * Set properties on one specific user. Existing properties will be replaced.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param userSetProprtiesRequests Array of set property requests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setProperties(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userSetProprtiesRequests: UserSetPropertyRequest, observe?: 'body', reportProgress?: boolean): Observable<UserSetPropertiesResponse>;
    public setProperties(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userSetProprtiesRequests: UserSetPropertyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSetPropertiesResponse>>;
    public setProperties(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userSetProprtiesRequests: UserSetPropertyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSetPropertiesResponse>>;
    public setProperties(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userSetProprtiesRequests: UserSetPropertyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling setProperties.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling setProperties.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling setProperties.');
        }

        if (userSetProprtiesRequests === null || userSetProprtiesRequests === undefined) {
            throw new Error('Required parameter userSetProprtiesRequests was null or undefined when calling setProperties.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserSetPropertiesResponse>(`${this.basePath}/user/setProperties`,
            userSetProprtiesRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set roles on one specific user
     * Set roles on one specific user. Existing roles will be replaced. All roles must exist. If one or more roles not exist, no roles will be appended. The set property is a transactional execution. Either all roles can be set, or the request fails.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param roles Array of roles to set on user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: UserSetRolesRequest, observe?: 'body', reportProgress?: boolean): Observable<UserSetGlobalResponse>;
    public setRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: UserSetRolesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSetGlobalResponse>>;
    public setRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: UserSetRolesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSetGlobalResponse>>;
    public setRoles(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, roles: UserSetRolesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling setRoles.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling setRoles.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling setRoles.');
        }

        if (roles === null || roles === undefined) {
            throw new Error('Required parameter roles was null or undefined when calling setRoles.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserSetGlobalResponse>(`${this.basePath}/user/setRoles`,
            roles,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set users information
     * Set users information
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param userSetRequests Array of set user requests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setUsers(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userSetRequests: Array<UserDTO>, observe?: 'body', reportProgress?: boolean): Observable<UserSetResponse>;
    public setUsers(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userSetRequests: Array<UserDTO>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSetResponse>>;
    public setUsers(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userSetRequests: Array<UserDTO>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSetResponse>>;
    public setUsers(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, userSetRequests: Array<UserDTO>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling setUsers.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling setUsers.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling setUsers.');
        }

        if (userSetRequests === null || userSetRequests === undefined) {
            throw new Error('Required parameter userSetRequests was null or undefined when calling setUsers.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserSetResponse>(`${this.basePath}/user/set`,
            userSetRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Start the cloud migration from HEP to beook.
     * Start the cloud migration from HEP to beook. No additional parameters are needed. Subject is taken from header, Encryption keys and Cognitos Id are stored in AUAs DB.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param migrationSubject User infos for migration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public startCloudMigration(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, migrationSubject?: UserGetRequest, observe?: 'body', reportProgress?: boolean): Observable<UserSetGlobalResponse>;
    public startCloudMigration(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, migrationSubject?: UserGetRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSetGlobalResponse>>;
    public startCloudMigration(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, migrationSubject?: UserGetRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSetGlobalResponse>>;
    public startCloudMigration(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, migrationSubject?: UserGetRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling startCloudMigration.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling startCloudMigration.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling startCloudMigration.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserSetGlobalResponse>(`${this.basePath}/user/startCloudMigration`,
            migrationSubject,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Toggle template properties
     * Toggle template properties
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param togglePropertyTemplates Array of property templates to toggle
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public togglePropertyTemplates(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, togglePropertyTemplates: Array<TogglePropertyTemplateRequest>, observe?: 'body', reportProgress?: boolean): Observable<PropertyToggleTemplatesResponse>;
    public togglePropertyTemplates(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, togglePropertyTemplates: Array<TogglePropertyTemplateRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PropertyToggleTemplatesResponse>>;
    public togglePropertyTemplates(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, togglePropertyTemplates: Array<TogglePropertyTemplateRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PropertyToggleTemplatesResponse>>;
    public togglePropertyTemplates(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, togglePropertyTemplates: Array<TogglePropertyTemplateRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling togglePropertyTemplates.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling togglePropertyTemplates.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling togglePropertyTemplates.');
        }

        if (togglePropertyTemplates === null || togglePropertyTemplates === undefined) {
            throw new Error('Required parameter togglePropertyTemplates was null or undefined when calling togglePropertyTemplates.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PropertyToggleTemplatesResponse>(`${this.basePath}/property/toggleTemplates`,
            togglePropertyTemplates,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
